/**
 * Processes and methods to load and store data for various records within the system.
 * the idea of this Mixing is to replace the CustomerParent and its child the TrunkParent logic which has limited levels of depth.
 * So we take the same ideas, put them in this mixing and include the mixing in components where we need it.
 * Note these methods are designed to be generic in nature.
 * Note that 'bc' mean 'Breeze Connect'
 */
import apiMixin from './apiMixin';
import GlobalHelperMixin from "./GlobalHelperMixin";
export default {
    mixins: [apiMixin,GlobalHelperMixin],
    computed: {
        /**
         * Return if any records are currently being fetched by the API.
         * This is global so take into consideration any fetches being used with this logic.
         * @returns {boolean}
         */
        fetchStoreLoading() {
            return Object.keys(this.$store.state.loading).length == 0;
        },
    },
    methods: {

        /**
         *
         * @param label label for the load
         * @param data either boolean or an object/null
         *
         * Note that this loading logic does not support stacking.
         * IE if 2 trunks are loading at the same time (label = 'trunk') then when the first one completes
         * the process will toggle off trunk loading and both will register as completed (not loading).
         *
         */
        fetchStoreSetLoading(label, data){
            this.$store.commit('loading', { name: label, data: data });
        },

        fetchStoreLoadingByLabel(label){
            /**
             * Note that the return value here can either be a boolean, object or null.
             * As this is used different. Mainly due to it being old code.
             * Also this could be updated to support a stack. If multiple with the same label are being loaded
             * then once the first one finishes it will toggle off loading for both.
             * However this is not an issue at the time of writing.
             */
            if(this.$store.state.loading[label]){
                return true;
            }else{
                return false;
            }
        },

        /**
         * Check if the same records is already stored
         * @param label record label
         * @param checkParams an object of key/values pairs used to check if the record matches.
         * EG
         * {
         *     "i_account":455
         * }
         * @returns {boolean}
         */
        fetchStoreIsAlreadyStored(label, checkParams){
            if(!this.$store.state.data[label]){
                return false;
            }
            let loaded = true;
            for (const property in checkParams) {
                if(String(this.$store.state.data[label][property]) != String(checkParams[property])){
                    loaded = false;
                }
            }
            return loaded;
        },
        /**
         * Will fetch and stored the data if needed
         * @param label used to label the storage location for the data
         * @param uri the URI used to fetch the data. EG customers/34/trunks/56
         * @param checkParams  an object of key/values pairs used to check if the record matches. This is used when we are checking if the record already exits in storage and to validate the fetched record.
         * {
         *     "i_account":455
         * }
         * @param failRedirect the object containing route data to redirect to on failure.
         * {
         *     name: 'CustomerSummary',
         *     params:{customerID : 34},
         * }
         * @param method the API fetch method. EG get/post/put/delete
         * @param force boolean if we are forcing the method to make the request. Whether or not the record is already stored.
         * @param friendlyName string name used for any messages shown to the user
         * @returns {Promise<void>}
         */
        async fetchStoreRequest(label, uri, checkParams, failRedirect, method, force, friendlyName) {
            if(!method){
                method = 'get';
            }
            if(!force){
                force = false;
            }
            //init the loading array
            if(!this.$store.state.loading.globalDefault){
                this.$store.commit('loading', { name: 'globalDefault', data: {} });
            }

            //loaded
            let loaded = this.fetchStoreIsAlreadyStored(label, checkParams);

            if(!force && loaded){
                if(this.$store.state.loading[label]){
                    //unset
                    //this.$store.commit('loading', { name: label, data: null });
                    this.fetchStoreSetLoading(label, null );
                }
            }else{
                if(loaded){
                    //reset
                    this.$store.commit('data', {
                        name:  label,
                        data: null,
                    });
                }
                this.fetchStoreSetLoading(label, checkParams );
                const response = await this.Api.send(method,uri,false);
                //not sure if loading should be toggled off here or at the end of the method. IE once all logic has been processed.
                this.fetchStoreSetLoading(label, null );

                let failure = false;

                if (response.success) {
                    let match = true;
                    if (Object.keys(checkParams).length > 0) {

                        if(!response.data) {
                            failure = true;
                        }
                        for (const property in checkParams) {
                            if (String(response.data[property]) != String(checkParams[property])) {
                                failure = true;
                            }
                        }
                    }
                }else{
                    failure = true;
                }

                if(!failure) {
                    this.fetchStoreCommitData(label,response.data);
                }else{
                    if(failRedirect) {
                        this.showGlobalErrorMessage('Failed to load '+friendlyName+'.');
                        this.$router.push(failRedirect);
                    }
                }
            }
        },

        fetchStoreCommitData(label,data){
            this.$store.commit('data', {
                name:  label,
                data: data,
            });
        },

        /**
         * Used to fetch the data from storage if it exists.
         * @param label
         * @param checkParams
         * @returns {{}|*}
         */
        fetchStoreGetData(label, checkParams){
            if(this.fetchStoreIsAlreadyStored(label, checkParams)){
                return this.$store.state.data[label];
            }else {
                return {};
            }
        }
    },

};